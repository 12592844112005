import React, { useEffect, createContext, useContext, useState } from "react";
import { QubicConnect, QubicConnectConfig } from "@qubic-connect/core";
import { gql } from "@apollo/client";
import { client } from "../index";
import useSessionStorage from "../hooks/useSessionStorage";

interface UpdateMemberInfoInput {
  newMemberUuid?: string;
  newMemberId?: number;
  newWalletAddress?: string;
  newUserInfoLoaded?: boolean;
}

interface AppContextType {
  memberUuid: string | null;
  memberId: number | null;
  walletAddress: string | null;
  userInfoLoaded: boolean | null;
  showLoginForBuy: boolean | null;
  isRedirectingLogin: boolean | null;
  qubicConnect: any;
  updateMemberInfo: ({
    newMemberUuid,
    newMemberId,
    newWalletAddress,
    newUserInfoLoaded,
  }: UpdateMemberInfoInput) => void;
  updateShowLoginForBuy: (newShowLoginForBuy: boolean) => void;
  updateIsRedirectingLogin: (newIsRedirectingLogin: boolean) => void;
}

const SDK_CONFIG: QubicConnectConfig = {
  name: "Qubic Creator", // a display name for future usage
  key: process.env.REACT_APP_API_KEY || "",
  secret: process.env.REACT_APP_API_SECRET || "",
  service: process.env.REACT_APP_API_SERVICE_NAME || "", //optional
};
console.log(
  "process.env.REACT_APP_REDIRECT_URI",
  process.env.REACT_APP_REDIRECT_URI,
);
console.log(
  "process.env.REACT_APP_GRAPHQL_ENDPOINT",
  process.env.REACT_APP_GRAPHQL_ENDPOINT,
);
console.log("process.env.REACT_APP_API_KEY", process.env.REACT_APP_API_KEY);
console.log(
  "process.env.REACT_APP_API_SECRET",
  process.env.REACT_APP_API_SECRET,
);
console.log(
  "process.env.REACT_APP_API_SERVICE_NAME",
  process.env.REACT_APP_API_SERVICE_NAME,
);
const qubicConnect = new QubicConnect(SDK_CONFIG);

const AppContext = createContext<AppContextType | undefined>(undefined);

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [memberUuid, setMemberUuid] = useState("");
  const [memberId, setMemberId] = useState<number | null>(null);
  const [walletAddress, setWalletAddress] = useState("");
  const [userInfoLoaded, setUserInfoLoaded] = useState(false);
  const [showLoginForBuy, setShowLoginForBuy] = useSessionStorage(
    "showLoginForBuy",
    false,
  );

  const [isRedirectingLogin, setIsRedirectingLogin] = useSessionStorage(
    "isRedirectingLogin",
    null,
  );

  const updateMemberInfo = ({
    newMemberUuid,
    newMemberId,
    newWalletAddress,
    newUserInfoLoaded,
  }: UpdateMemberInfoInput) => {
    newMemberUuid && setMemberUuid(newMemberUuid);
    newMemberId && setMemberId(newMemberId);
    newWalletAddress && setWalletAddress(newWalletAddress);
    newUserInfoLoaded && setUserInfoLoaded(newUserInfoLoaded);
  };

  const updateShowLoginForBuy = (newShowLoginForBuy: boolean) => {
    if (newShowLoginForBuy !== undefined) {
      setShowLoginForBuy(newShowLoginForBuy);
    }
  };

  const updateIsRedirectingLogin = (newIsRedirectingLogin: boolean) => {
    if (newIsRedirectingLogin !== undefined) {
      setIsRedirectingLogin(newIsRedirectingLogin);
    }
  };

  const waitForAuthStateChanged = () => {
    return new Promise((resolve, reject) => {
      qubicConnect.onAuthStateChanged((user, error) => {
        if (error) {
          reject(error);
        } else {
          resolve(user);
        }
      });
    });
  };

  useEffect(() => {
    const handleAuthStateChanged = async () => {
      try {
        const user = await waitForAuthStateChanged();
        console.log("開始onAuthStateChanged", user, memberId);
        if (user) {
          // 存進 useContext
          updateIsRedirectingLogin(true);
          updateMemberInfo({
            newWalletAddress: (user as { address?: string })?.address,
          });
          updateShowLoginForBuy(false);

          // 存進 DB
          // 應該要擋資料庫已存在的問題！！！！先後端擋
          const addNFTWalletToMember = async () => {
            const result = await client.mutate({
              mutation: gql`
                mutation Mutation(
                  $memberId: Int!
                  $walletAddress: String!
                  $mwSettingId: Int
                ) {
                  addNFTWalletToMember(
                    memberId: $memberId
                    walletAddress: $walletAddress
                    mwSettingId: $mwSettingId
                  ) {
                    mwMembersId
                    walletAddress
                    memberId
                  }
                }
              `,
              variables: {
                memberId: memberId,
                walletAddress: (user as { address?: string })?.address,
              },
            });
            console.log("執行addNFTWalletToMember", result);
          };

          memberId !== null && addNFTWalletToMember();
        } else {
          console.log("isRedirectingLogin 全局 else");
          updateIsRedirectingLogin(false);
        }
      } catch (error) {
        console.log("開始onAuthStateChanged error", error);
      }
    };

    handleAuthStateChanged();
  }, [memberId]);

  return (
    <AppContext.Provider
      value={{
        memberUuid,
        memberId,
        walletAddress,
        userInfoLoaded,
        showLoginForBuy,
        isRedirectingLogin,
        qubicConnect,
        updateMemberInfo,
        updateShowLoginForBuy,
        updateIsRedirectingLogin,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
