import { Link } from "react-router-dom";
import iconCollectibles from "../public/icons/icon-collectibles.svg";
import iconNFTList from "../public/icons/icon-NFT-list.svg";

export const Footer = () => {
  return (
    <nav className="footer-btns">
      <ul className="menu">
        <li>
          <div className="li-a">
            <Link to="/collectibles">
              <div className="icon">
                <img src={iconCollectibles} alt="我的收藏" />
              </div>
              <p>我的收藏</p>
            </Link>
          </div>
        </li>
        <li>
          <div className="li-a">
            <Link to="/">
              <div className="icon">
                <img src={iconNFTList} alt="最新拍賣" />
              </div>
              <p>最新拍賣</p>
            </Link>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Footer;
