import React from "react";
import ReactDOM from "react-dom/client";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { register } from "swiper/element/bundle";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/element/css/effect-coverflow";
import "swiper/element/css/autoplay";
import { AppProvider } from "./contexts/appContext";
import App from "./App";
import "./index.scss";

export const client = new ApolloClient({
  uri: `${process.env.REACT_APP_GRAPHQL_ENDPOINT || ""}/apis/graphql`,
  cache: new InMemoryCache(),
});

register();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <AppProvider>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </AppProvider>
  </React.StrictMode>,
);
