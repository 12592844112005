// memberId、memberUuid：、walletAddress 值都由 useContext 給予
// memberUuid：每次渲染由 SSO qcsso.getProfile() 取得
// memberId：每次渲染看 useContext 有沒有(包括檢查 sessionStorage 有沒有)，再沒有才從 api 拿
// walletAddress：每次渲染看 useContext 有沒有(包括檢查 sessionStorage 有沒有)，再沒有才從 api 拿。後端 DB 沒有才要登入 Qubic 綁定

import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { gql } from "@apollo/client";
import { client } from "./index";
import { useAppContext } from "./contexts/appContext";
import Collectibles from "./pages/collectibles";
import Store from "./pages/store";
import StoreDetail from "./pages/storeDetail";
import OrderCompleted from "./pages/orderCompleted";
import Page404 from "./pages/404";
import Footer from "./components/footer";
import Login from "./components/login";

function App() {
  const {
    memberUuid,
    memberId,
    walletAddress,
    userInfoLoaded,
    showLoginForBuy,
    updateMemberInfo,
  } = useAppContext();
  console.log("useAppContext memberId", memberId);
  console.log("useAppContext memberUuid", memberUuid);
  console.log("useAppContext walletAddress", walletAddress);
  console.log("useAppContext userInfoLoaded", userInfoLoaded);
  console.log("useAppContext showLoginForBuy", showLoginForBuy);

  // 等同於 <script type="text/javascript" src="https://sso-dev.quickclick.cc/sdk/latest/qcsso-dev.js"></script>，但可以抽換 src 為環境變數
  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_SDK_SRC || "";
    script.type = "text/javascript";
    // onload 事件確定腳本載入完成，做後續操作。
    script.onload = () => {
      // TTPush SSO，取得 memberUuid
      qcsso
        .init({
          appId: process.env.REACT_APP_APP_ID || "",
          params: { referralCode: "" },
        })
        .then(() => {
          if (!qcsso.isLoggedIn()) {
            qcsso.login({
              redirectUrl: process.env.REACT_APP_REDIRECT_URI || "",
            });
          } else {
            qcsso.getProfile().then((user: any) => {
              updateMemberInfo({ newMemberUuid: user.user.userId });
            });
          }
        });
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // 取得 memberId、walletAddress
  useEffect(() => {
    const getUserInfo = async () => {
      const result = await client.query({
        query: gql`
          query GetUserInfo($memberUuid: String!, $mwSettingId: Int) {
            getUserInfo(memberUuid: $memberUuid, mwSettingId: $mwSettingId) {
              walletAddress
              memberId
            }
          }
        `,
        variables: {
          memberUuid,
        },
      });
      console.log("執行getUserInfo", result);

      const { memberId, walletAddress } = result?.data?.getUserInfo;
      updateMemberInfo({ newMemberId: memberId });
      walletAddress &&
        updateMemberInfo({
          newWalletAddress: walletAddress,
        });

      updateMemberInfo({ newUserInfoLoaded: true });
    };

    if ((!memberId || !walletAddress) && memberUuid) {
      getUserInfo();
    }
  }, [memberUuid, memberId, walletAddress]);

  // 需要有 wallet address：1. 我的收藏頁。2. 按購買 NFT 的按鈕
  const getRenderPage = (page: React.ReactNode) => {
    if (!userInfoLoaded) {
      return <main className="TTPushNFT-container"></main>; // loading 圖示
    }

    return memberId && walletAddress ? page : <Login />;
  };

  const getRenderStoreDetailPage = (page: React.ReactNode) => {
    return showLoginForBuy ? <Login /> : page;
  };

  return (
    <Router>
      {/* <div>showLoginForBuy: {showLoginForBuy ? "true" : "false"}</div>
      <div>會員uuid: {memberUuid}</div>
      <div>會員id: {memberId}</div>
      <div>會員walletAddress: {walletAddress}</div>
      <div
        onClick={() =>
          qcsso
            .logout({
              logoutRedirectUri: process.env.VUE_APP_REDIRECT_URI,
            })
            .then(() => {
              console.log("登出完成");
            })
            .catch((err: any) => console.log("err", err))
        }
      >
        登出TTPush
      </div> */}
      <Routes>
        <Route path="/" element={<Store />} />
        <Route
          path="/:id"
          element={getRenderStoreDetailPage(<StoreDetail />)}
        />
        <Route
          path="/:id/order-completed"
          element={getRenderPage(<OrderCompleted />)}
        />
        <Route path="/collectibles" element={getRenderPage(<Collectibles />)} />
        <Route path="*" element={<Page404 />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
