// 待改善：文字...。toggleExpand。
import { useEffect } from "react";
import { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import _ from "lodash";
import { useAppContext } from "../contexts/appContext";
import { INFT } from "../lib/types";
import Loading from "../components/loading";

export const Collectibles = () => {
  const { walletAddress } = useAppContext();

  const { loading, error, data } = useQuery(
    gql`
      query GetUserCollectibles($userAddress: String!) {
        getUserCollectibles(userAddress: $userAddress) {
          count
          data {
            name
            imageUrl
            description
          }
        }
      }
    `,
    {
      variables: {
        userAddress: walletAddress,
      },
      fetchPolicy: "network-only",
    },
  );
  console.log("測試apollo client loading", loading);
  console.log("測試apollo client error", error);
  console.log("測試apollo client data", data);

  const [expanded, setExpanded] = useState<boolean>(false);
  const [expandedTarget, setExpandedTarget] = useState<number | null>(null);

  const toggleExpand = (id: number) => {
    setExpanded(!expanded);
    setExpandedTarget(id);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* nft 收藏都是單獨展示，不會有同種類顯示有多少數量 */}
      <main className="TTPushNFT-container">
        <h2 className="main-title">我的收藏</h2>
        {loading ? (
          <Loading />
        ) : _.isEmpty(data?.getUserCollectibles?.data) ? (
          <h4 className="loading-container note">目前沒有收藏品</h4>
        ) : (
          <section className="middle-container">
            {/* 收藏卡片 */}
            <div className="item-content collect-list">
              {data?.getUserCollectibles?.data?.map(
                (item: INFT, id: number) => (
                  <div className="pro-content" key={id}>
                    <div className="top-photo">
                      <div className="image-container">
                        <img src={item?.imageUrl} alt={item?.name} />
                      </div>
                    </div>
                    <div className="info">
                      <h3>{item?.name}</h3>
                      <h4>商品敘述</h4>
                      <p
                        className={`truncate ${
                          expanded && id === expandedTarget ? "expand" : ""
                        }`}
                        onClick={() => toggleExpand(id)}
                      >
                        {item?.description}
                      </p>
                    </div>
                  </div>
                ),
              )}
            </div>
          </section>
        )}
      </main>
    </>
  );
};

export default Collectibles;
