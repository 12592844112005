import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import _ from "lodash";
import debounce from "lodash.debounce";
import { client } from "../index";
import { useAppContext } from "../contexts/appContext";
import Page404 from "../pages/404";
import Loading from "../components/loading";

export const StoreDetail = () => {
  const [isMintFreeNFTLoading, setIsMintFreeNFTLoading] =
    useState<boolean>(false);
  console.log("isMintFreeNFTLoading", isMintFreeNFTLoading);

  const [
    isStartDDPayWithoutUserInfoLoaded,
    setIsStartDDPayWithoutUserInfoLoaded,
  ] = useState<boolean>(false);
  console.log(
    "isStartDDPayWithoutUserInfoLoaded",
    isStartDDPayWithoutUserInfoLoaded,
  );

  let navigate = useNavigate();

  const assetId = useLocation().pathname.substring(1);
  console.log("assetId", assetId);

  const { loading, error, data } = useQuery(
    gql`
      query GetNFT($assetId: String!) {
        getNFT(assetId: $assetId) {
          id
          description
          availableQuantity
          imageThumbnailUrl
          imageUrl
          name
          price
          soldQuantity
        }
      }
    `,
    {
      variables: {
        assetId,
      },
    },
  );
  console.log("測試apollo client loading", loading);
  console.log("測試apollo client error", error);
  console.log("測試apollo client data", data);

  const {
    id,
    name,
    description,
    imageUrl,
    imageThumbnailUrl,
    availableQuantity,
    soldQuantity,
    price,
  } = data?.getNFT || {};

  const {
    memberUuid,
    memberId,
    walletAddress,
    userInfoLoaded,
    updateShowLoginForBuy,
  } = useAppContext();

  const handleMintFreeNFT = async ({
    assetId,
    memberId,
  }: {
    assetId: string;
    memberId: number;
  }) => {
    setIsMintFreeNFTLoading(true);

    // 建立 DB 訂單
    const createOrderResult = await client.mutate({
      mutation: gql`
        mutation CreateOrder($data: createOrderInput) {
          createOrder(data: $data) {
            orderId
          }
        }
      `,
      variables: {
        data: {
          amount: 0,
          assetId,
          memberId,
          quantity: 1,
        },
      },
    });
    console.log("執行CreateOrder", createOrderResult);

    // 空投
    const mintNFTResult = await client.mutate({
      mutation: gql`
        mutation MintNFT($data: mintNFTInput) {
          mintNFT(data: $data) {
            orderState
            qubicOrderId
          }
        }
      `,
      variables: {
        data: {
          assetId: id,
          recipients: [
            {
              address: walletAddress,
              quantity: 1,
            },
          ],
        },
      },
    });
    console.log("執行MintNFT", mintNFTResult);

    // 完成 DB 訂單
    const completeOrderResult = await client.mutate({
      mutation: gql`
        mutation CompleteOrder($data: completeOrderInput) {
          completeOrder(data: $data)
        }
      `,
      variables: {
        data: {
          mintStatus: mintNFTResult?.data?.mintNFT?.orderState,
          orderId: createOrderResult?.data?.createOrder?.orderId,
          qubicOrderId: mintNFTResult?.data?.mintNFT?.qubicOrderId,
        },
      },
    });
    console.log("執行CompleteOrder", completeOrderResult);
  };

  const startDDPay = async () => {
    if (userInfoLoaded) {
      if (memberId && walletAddress) {
        if (Number(price) === 0) {
          // 價格零元，不用跑 DDPay
          await handleMintFreeNFT({ assetId: id, memberId });
          navigate(`/${assetId}/order-completed`);
          setIsMintFreeNFTLoading(false);
        } else {
          // 價格非零元，跑 DDPay
          const result = await client.mutate({
            mutation: gql`
              mutation Mutation($data: startDDPayInput) {
                startDDPay(data: $data)
              }
            `,
            variables: {
              data: {
                amount: Number(price) * 1,
                assetId: id,
                memberUuid,
                memberId,
                quantity: 1,
              },
            },
          });
          console.log("result?.data?.startDDPay", result?.data?.startDDPay);
          if (result) {
            window.location.href = result?.data?.startDDPay;
          }
        }
      } else {
        updateShowLoginForBuy(true);
      }
    } else {
      setIsStartDDPayWithoutUserInfoLoaded(true);
    }
  };

  // 防抖
  const debouncedStartDDPay = debounce(() => {
    startDDPay();
  }, 1000);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [assetId]);

  // 處理按下購買按鈕時，GetUserInfo api 還沒跑完的情況
  useEffect(() => {
    if (isStartDDPayWithoutUserInfoLoaded && userInfoLoaded) {
      startDDPay();
      setIsStartDDPayWithoutUserInfoLoaded(false);
    }
  }, [isStartDDPayWithoutUserInfoLoaded, userInfoLoaded]);

  if (!loading && _.isEmpty(data?.getNFT)) {
    return <Page404 />;
  }

  return (
    <main className="TTPushNFT-container">
      <section className="middle-container">
        <div className="item-content">
          {loading || isMintFreeNFTLoading ? (
            <Loading />
          ) : (
            <div className="pro-content">
              <p className="gold">
                ${(Number(price) * 10).toLocaleString()} 枚金幣
              </p>
              <div className="top-photo">
                <img src={imageUrl} alt={name} />
              </div>
              <div className="info">
                <h3>{name}</h3>
                <h4>商品敘述</h4>
                <p>{description}</p>
                {availableQuantity > 0 && (
                  <p className="buy" onClick={debouncedStartDDPay}>
                    <a>購買</a>
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </main>
  );
};

export default StoreDetail;
