import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

export const Page404 = () => {
  let navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 3000);
  }, []);

  return (
    <main className="TTPushNFT-container">
      <h2 className="main-title">404</h2>
      <h4 className="note">您訪問的頁面不存在</h4>
      <Link to="/">
        <h4 className="loading-container note">回首頁</h4>
      </Link>
    </main>
  );
};

export default Page404;
