import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import _ from "lodash";
import iconDone from "../public/icons/icon-done.png";
import Loading from "../components/loading";

export const OrderCompleted = () => {
  let location = useLocation();
  const regex = /\/(\d+)\/order-completed/;
  const urlId = (location.pathname.match(regex) as any)[1].toString();
  console.log("urlId", urlId);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { loading, error, data } = useQuery(
    gql`
      query GetNFT($assetId: String!) {
        getNFT(assetId: $assetId) {
          id
          description
          availableQuantity
          imageThumbnailUrl
          imageUrl
          name
          price
          soldQuantity
        }
      }
    `,
    {
      variables: {
        assetId: urlId,
      },
    },
  );
  console.log("測試apollo client loading", loading);
  console.log("測試apollo client error", error);
  console.log("測試apollo client data", data);
  const {
    id,
    name,
    description,
    imageUrl,
    imageThumbnailUrl,
    availableQuantity,
    soldQuantity,
    price,
  } = data?.getNFT || {};

  return (
    <>
      <main className="TTPushNFT-container">
        <section className="middle-container">
          <div className="item-content">
            {loading ? (
              <Loading />
            ) : (
              <div className="pro-content">
                <p className="gold">
                  ${(Number(price) * 10).toLocaleString()}
                  枚金幣
                </p>
                <div className="top-photo">
                  <img src={imageUrl} alt={name} />
                </div>
                <div className="info done">
                  <div className="img-done">
                    <img src={iconDone} alt="購買完成！" />
                  </div>
                  <h3>恭喜您購買成功！</h3>
                  <h4>
                    空投NFT至<Link to="/collectibles"> 我的收藏 </Link>中
                  </h4>
                  <h4>請稍等幾分鐘！</h4>
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
    </>
  );
};

export default OrderCompleted;
