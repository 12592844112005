import { useEffect } from "react";
import { useAppContext } from "../contexts/appContext";
import Loading from "../components/loading";

export const Login = () => {
  const {
    qubicConnect,
    walletAddress,
    isRedirectingLogin,
    updateIsRedirectingLogin,
  } = useAppContext();

  // isRedirectingLogin：讓 walletAddress 正在載入時，不要一直彈出登入 Qubic 畫面，因為這裡不是手動按鈕啟動登入 Qubic
  console.log("isRedirectingLogin", isRedirectingLogin);

  const redirectLogin = () => {
    qubicConnect.loginWithRedirect();
  };

  useEffect(() => {
    if (!walletAddress && !isRedirectingLogin) {
      updateIsRedirectingLogin(true);
      redirectLogin();
    }
  }, [walletAddress, isRedirectingLogin]);

  return (
    <>
      <main className="TTPushNFT-container loading-container">
        <Loading />
      </main>
    </>
  );
};

export default Login;
