import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { useAppContext } from "../contexts/appContext";
import { INFT } from "../lib/types";
// import bannerMain from "../public/images/banner-main.png";
import bannerMid from "../public/images/banner-mid.png";
import banner2 from "../public/images/banner-mid-2.png";
import bannerNew from "../public/images/banner-new.png";
import Loading from "../components/loading";

export const Store = () => {
  const { updateShowLoginForBuy } = useAppContext();

  useEffect(() => {
    updateShowLoginForBuy(false);
    window.scrollTo(0, 0);
  }, []);

  const { loading, error, data } = useQuery(
    gql`
      query GetNFTList(
        $sortOrder: AssetsSortOrder
        $chainIds: [Int!]
        $onlyAvailable: Boolean
      ) {
        getNFTList(
          sortOrder: $sortOrder
          chainIds: $chainIds
          onlyAvailable: $onlyAvailable
        ) {
          count
          data {
            id
            description
            name
            imageUrl
            imageThumbnailUrl
            price
            availableQuantity
            soldQuantity
          }
        }
      }
    `,
    {
      variables: {
        // sortOrder: "PUBLISH_TIME_DESC",
        // chainIds: [Number(process.env.REACT_APP_CHAIN_ID)],
        onlyAvailable: false,
      },
    },
  );
  console.log("測試apollo client loading", loading);
  console.log("測試apollo client error", error);
  console.log("測試apollo client data", data);

  return (
    <>
      <main className="TTPushNFT-container">
        <section className="middle-container">
          {/* <div className="top-content">
            <div className="wow bounce an-repeat" data-wow-duration="3s">
              <img src={bannerMain} alt="TTPush NFT" />
            </div>
          </div> */}

          {/* banner swiper */}
          <div className="banner-content">
            <swiper-container pagination="true" loop="true" autoplay="true">
              <swiper-slide>
                <img src={bannerMid} alt="TTPush NFT" />
              </swiper-slide>
              <swiper-slide>
                <img src={banner2} alt="TTPush NFT" />
              </swiper-slide>
            </swiper-container>
          </div>

          {/* NFT卡片 swiper */}
          {loading ? (
            <Loading />
          ) : (
            <div className="item-content">
              <swiper-container
                className="mySwiper"
                pagination="true"
                effect="coverflow"
                grab-cursor="true"
                centered-slides="true"
                slides-per-view="auto"
                coverflow-effect-rotate="50"
                coverflow-effect-stretch="0"
                coverflow-effect-depth="100"
                coverflow-effect-modifier="1"
                coverflow-effect-slide-shadows="true"
              >
                {data?.getNFTList?.data?.map((item: INFT, id: number) => (
                  <swiper-slide key={item?.name}>
                    {id === 0 && (
                      <div className="img-new">
                        <img src={bannerNew} alt="banner-new" />
                      </div>
                    )}
                    <div className="top-photo">
                      <img
                        src={item?.imageThumbnailUrl}
                        alt={item?.name}
                        width={220}
                        height={220}
                      />
                    </div>
                    <div className="info">
                      <h3>{item?.name}</h3>
                      <p>目前價格：{Number(item?.price) * 10} 枚</p>
                      <p>
                        已賣出：
                        {item?.soldQuantity} 件
                      </p>
                      <p>剩餘：{item?.availableQuantity} 件</p>
                      <p className="buy">
                        <div>
                          <Link to={`/${item?.id}`}>購買</Link>
                        </div>
                      </p>
                    </div>
                  </swiper-slide>
                ))}
              </swiper-container>
            </div>
          )}
        </section>
      </main>
    </>
  );
};

export default Store;
