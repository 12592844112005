import ReactLoading from "react-loading";

export const Loading = () => {
  return (
    <div className="loading-container">
      <ReactLoading type="spinningBubbles" />
    </div>
  );
};

export default Loading;
